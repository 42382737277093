import axios from 'axios'
import jwt_decode from "jwt-decode";
import dayjs from 'dayjs'
import backendIp from '../../backendIp.json'

const baseURL = backendIp['ip']

const useAxios=()=>{
  const axiosInstance = axios.create({
    baseURL,
    headers:{Authorization: `Bearer ${localStorage.getItem('authTokenAccess')}`}
  });

  axiosInstance.interceptors.request.use(async req =>{
      
    const payload=jwt_decode(String(localStorage.getItem('authTokenAccess')))
    const isExpired = dayjs.unix(payload.exp).diff(dayjs()) < 1;

    if(!isExpired){
      return req
    } 
    else{
      const response = await axios.post(`${baseURL}/api/token/refresh/`, {
        refresh: localStorage.getItem('authTokenRefresh')
      });
  
      localStorage.setItem("authTokenRefresh",response.data['refresh']);
      localStorage.setItem("authTokenAccess",response.data['access']);
  
      req.headers.Authorization = `Bearer ${response.data['access']}`
      return req    
    }
  })

  return axiosInstance
}

export default useAxios;
